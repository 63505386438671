/* eslint-disable @typescript-eslint/no-explicit-any */
import {
	APPS,
	fetchGetAppData,
	setShowPropertyModal,
	useAppDispatch,
	useAppSelector
} from '@hospy/store';
import { Layout as AntLayout, Spin } from 'antd';
import { motion } from 'framer-motion';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import styled, { css, useTheme } from 'styled-components';
import AddPhoneChannelPropertyModal from '../../../components/add-phone-channel-property-modal/add-phone-channel-property-modal';
import PropertyModal from '../../../components/property-modal/property-modal';
import useValidateShowNewRates from '../../../pages/show-new-rates/use-validate-show-new-rates';
import AppNavBar from '../../organisms/app-nav-bar';
import AppSideBar from '../../organisms/app-side-bar';

const Wp = styled.div`
	position: relative;
	width: calc(100% - 80px);
	height: calc(100dvh - 81px);
	margin-top: 81px;
	margin-left: 80px;
	${({ theme }) =>
		theme.screens['xs'] &&
		css`
			width: 100%;
			margin-left: 0;
		`}
`;

const Main = styled(({ fullWidth, visibleExpirationAlert, ...props }) => (
	<AntLayout {...props} />
))`
	width: 100%;
	max-width: ${({ theme }) => theme.maxWidth}px;
	margin: 0 auto;
	padding: 0 15px;

	/* ${({ theme, visibleExpirationAlert }) =>
		theme.screens['md'] &&
		css`
			margin-top: ${visibleExpirationAlert ? 0 : 80}px;
		`}
	${({ theme, visibleExpirationAlert }) =>
		theme.screens['xs'] &&
		css`
			padding: 0 10px;
			margin-top: ${visibleExpirationAlert ? 120 : 80}px;
		`} */

	${({ fullWidth }) =>
		fullWidth &&
		css`
			max-width: 100%;
		`}
`;

const LoadingChangeProperty = styled.div`
	position: absolute;
	width: 100%;
	min-height: 100%;
	z-index: 1;
	display: flex;
	justify-content: center;
	align-items: center;
	backdrop-filter: blur(5px);
`;

interface Props {
	children?: React.ReactElement | React.ReactElement[] | undefined;
	icon?: React.ElementType;
	title: string;
	titleRight?: string;
	menu?: React.ReactElement;
	pathname?: string | undefined;
	loading?: boolean;
	fullWidth?: boolean;
	hideSelectProperty?: boolean;
	hideAppButton?: boolean;
	mainStyle?: any;
}

export const Container = ({
	children,
	icon,
	title,
	titleRight,
	menu,
	pathname,
	loading,
	fullWidth,
	hideSelectProperty,
	hideAppButton,
	mainStyle
}: Props) => {
	const theme: any = useTheme();
	const dispatch = useAppDispatch();

	const { loading: loadingValidateShowNewRates } = useValidateShowNewRates();

	const { showPropertyModal } = useAppSelector(({ start }) => start);
	const { user, propertyId } = useAppSelector(({ user }) => user);

	const { appData } = useAppSelector(({ common }) => common);

	const [daysToExpire, setDaysToExpire] = useState<number | null>(null);

	const validateOriginApp = (
		envs: { env: string; name: string }[]
	): string => {
		const appKey =
			envs.find((possibleApp) =>
				process.env[possibleApp.env]?.includes(window.location.origin)
			)?.name || '';

		return appKey;
	};

	useEffect(() => {
		if (!appData || !user) return;

		const currentProperty = user.properties?.find(
			(property) => property._id === propertyId
		);

		const currentAppDetails = currentProperty?.apps?.find(
			(app) => app._id === appData._id
		);

		if (currentAppDetails?.subscription === 'trial') {
			const expirationDate = moment(
				moment(currentAppDetails?.expirationDate).format('YYYY-MM-DD')
			).hour(23);

			const today = moment().subtract(1, 'd').hour(0);

			const daysLocal = moment(moment(expirationDate)).diff(today, 'day');

			setDaysToExpire(daysLocal >= 0 ? daysLocal : 0);
		} else {
			setDaysToExpire(null);
		}
	}, [appData, user, user, propertyId]);

	useEffect(() => {
		const appKey = validateOriginApp([
			{ env: 'NX_APP_COLOMBIA_SIRE_TRA', name: 'SIRE_TRA' },
			{ env: 'NX_APP_CHECKIN_EXPRESS', name: 'CHECKIN_EXPRESS' },
			{ env: 'NX_APP_PAYMENT_COLLECT', name: 'PAYMENT_COLLECT' },
			{ env: 'NX_APP_INVOICE_MANAGER', name: 'INVOICE_MANAGER' }
		]);

		const appId = APPS[appKey as keyof typeof APPS];

		if (appId) dispatch(fetchGetAppData(appId));
	}, []);

	return (
		<AntLayout style={{ minHeight: '100vh', height: '100%' }}>
			<AppNavBar
				icon={icon}
				title={title}
				titleRight={titleRight}
				menu={menu}
				hideSelectProperty={hideSelectProperty}
				appData={appData}
				daysToExpire={daysToExpire}
			/>
			{!theme.screens.xs && (
				<AppSideBar
					title={title}
					menu={menu}
					hideAppButton={hideAppButton}
				/>
			)}
			<Wp>
				{loading && (
					<LoadingChangeProperty>
						<Spin size="large" />
					</LoadingChangeProperty>
				)}
				<motion.div
					key={pathname}
					initial={{ opacity: 0, x: 100 }}
					animate={{ opacity: 1, x: 0 }}
					exit={{ opacity: 0, x: -100 }}
				>
					<Main
						id="mainContainer"
						fullWidth={fullWidth}
						visibleExpirationAlert={
							appData?.pricingModel !== 'free' &&
							daysToExpire !== null &&
							(theme.screens['xs'] ||
								(theme.screens['sm'] && !theme.screens['lg']))
						}
						style={mainStyle}
					>
						{children}
					</Main>
				</motion.div>
			</Wp>
			<UpdateExtraPropertyInfoModal />
			<PropertyModal
				open={showPropertyModal}
				onClose={() => dispatch(setShowPropertyModal(false))}
			/>
		</AntLayout>
	);
};

const UpdateExtraPropertyInfoModal = () => {
	const { property } = useAppSelector(({ user }) => user);
	const [open, setOpen] = useState(false);

	useEffect(() => {
		if (!property) return;
		if (!property?.phone) setOpen(true);
	}, [property?._id]);

	return (
		<AddPhoneChannelPropertyModal
			open={open}
			onClose={() => setOpen(false)}
		/>
	);
};

export const Menu = styled.div`
	display: flex;
	flex-direction: column;
	width: 100%;
	gap: 15px;
	${({ theme }) =>
		theme.screens['xs'] &&
		css`
			gap: 0;
		`}
`;
interface IMenuItemProps {
	isSelected?: boolean;
}

export const MenuItem = ({ to, icon: Icon, isSelected, children }: any) => {
	const theme: any = useTheme();

	return (
		<MenuItemContainer isSelected={isSelected}>
			<Link to={to}>
				{Icon && (
					<Icon style={{ fontSize: theme.screens.xs ? 20 : 30 }} />
				)}
				{children}
			</Link>
		</MenuItemContainer>
	);
};

const MenuItemContainer = styled.div<IMenuItemProps>`
	background-color: ${({ theme, isSelected }: any) =>
		isSelected
			? theme.colors.primary.darken['50%']
			: theme.colors.primary.base};
	&:hover {
		background-color: ${({ theme, isSelected }: any) =>
			isSelected
				? theme.colors.primary.darken['30%']
				: theme.colors.primary.lighten['20%']};
	}
	> a {
		color: #fff;
		display: flex;
		flex-direction: column;
		align-content: center;
		align-items: center;
		padding: 15px 0;
		gap: 10px;
	}

	${({ theme }) =>
		theme.screens['xs'] &&
		css`
			&,
			&:hover {
				background-color: transparent;
			}

			> a {
				color: #000;
				flex-direction: row;
				justify-content: center;
				padding: 11.4px 15px;
			}
			/* margin-bottom: 2rem; */
			border-bottom: 1px solid rgba(0, 0, 0, 0.06);
			margin-bottom: 1rem;
			padding-bottom: 1rem;
		`}
`;
