import AppsIcon from '@2fd/ant-design-icons/lib/Apps';
import { MenuOutlined } from '@ant-design/icons';
import { minDevice } from '@hospy/feature/devices';
import { Button as BtnHospy, Icon } from '@hospy/hospy-ui';
import { LogoIcon } from '@hospy/shared-components';
import { useAppSelector } from '@hospy/store';
import { Drawer as AntDrawer, Button, Layout, Space } from 'antd';
import { useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { useNavigate } from 'react-router-dom';
import styled, { css, useTheme } from 'styled-components';
import { DrawerMenu, LeftMenu, RightMenu } from './nav-bar-menu';

const { Header: AntHeader } = Layout;

interface Props {
	style?: {
		container?: React.CSSProperties;
		nav?: React.CSSProperties;
	};
	renderMenuButton?: React.ReactElement;
	hideAddPropertyData?: boolean;
	logoType?: string
}
export function NavBar({
	style,
	renderMenuButton,
	hideAddPropertyData, 
	logoType
}: Props) {
	const navigate = useNavigate();
	const { appName } = useAppSelector(({ user }) => user);
	const { isAuth } = useAppSelector(({ user }) => user);
	const { language } = useAppSelector(({ language }) => language);
	const theme: any = useTheme();

	const currentTheme = 'light';

	const [visible, setVisible] = useState(false);
	const toggleDrawer = (status: boolean) => {
		setVisible(status);
	};

	const BtnStart = () => (
		<StartButton
			type="text"
			shape="round"
			onClick={() => {
				window.location.href = process.env['NX_APP_START']
					? `${process.env['NX_APP_START']}?language=${language}`
					: `?language=${language}`;
			}}
			icon={<AppsIcon style={{ fontSize: theme.screens.xs ? 40 : 20 }} />}
		>
			{!theme.screens.xs ? 'Ingresar' : ''}
		</StartButton>
	);

	return (
		<Content style={{ ...style?.container }}>
			<Header style={{ ...style?.nav }}>
				<Section>
					{renderMenuButton}
					<LogoIcon
						logoType={logoType}
						style={{ cursor: 'pointer' }}
						onClick={() => {
							navigate(`/`, { replace: true });
						}}
					/>
				</Section>
				<SectionMenu>
					{appName !== 'MAIN' && <LeftMenu theme={currentTheme} />}
				</SectionMenu>
				<SectionMenu style={{ justifyContent: 'end', gap: 15 }}>
					{!isAuth && appName === 'MAIN' && (
						<Space size={15}>
							<BtnBegin
								type="primary"
								shape="round"
								ghost
								onClick={() => {
									// navigate(`/register`);
									window.location.href =
										'https://hospy.typeform.com/to/fLDIZ7jy';
								}}
								style={{ minWidth: 135 }}
							>
								Pruébalo ahora
							</BtnBegin>
							<Button
								type="primary"
								shape="round"
								onClick={() => {
									// navigate(`/login`);
									window.location.href =
										process.env['NX_APP_START'] + 'login';
								}}
								style={{
									minWidth: 135,
									padding: '15px 15px',
									height: 'auto'
								}}
							>
								<FormattedMessage
									id="nav-bar.signin"
									defaultMessage="Iniciar sesión"
								/>
							</Button>
						</Space>
					)}
					{isAuth && appName === 'MAIN' && <BtnStart />}
					{isAuth && (
						<RightMenu
							style={
								appName === 'MAIN'
									? { height: 50, width: 50 }
									: {}
							}
						/>
					)}
				</SectionMenu>
				<SectionButton style={{ justifyContent: 'end', gap: 20 }}>
					{isAuth && appName === 'MAIN' && <BtnStart />}
					<Button
						style={{
							width: '2.5rem',
							height: '2.5rem',
							padding: 0
						}}
						type="link"
						icon={<MenuOutlined style={{ fontSize: 30 }} />}
						onClick={() => toggleDrawer(true)}
					/>
					<Drawer
						placement="top"
						closable={false}
						open={visible}
						height={appName === 'MAIN' ? '30%' : '90%'}
						onClose={() => toggleDrawer(false)}
					>
						<div
							style={{
								display: 'flex',
								justifyContent: 'flex-end',
								padding: '0 15px'
							}}
						>
							<Button
								type="text"
								icon={
									<Icon
										style={{ fontSize: 30 }}
										material="close"
										ant
									/>
								}
								onClick={() => toggleDrawer(false)}
							/>
						</div>
						{!isAuth && appName === 'MAIN' && (
							<Space
								align="center"
								direction="vertical"
								style={{ width: '100%' }}
							>
								<BtnBegin
									type="primary"
									shape="round"
									ghost
									onClick={() => {
										// navigate(`/register`);
										window.location.href =
											'https://hospy.typeform.com/to/fLDIZ7jy';
									}}
									style={{ minWidth: 250 }}
								>
									Pruébalo ahora
								</BtnBegin>
								<Button
									type="primary"
									shape="round"
									onClick={() => {
										// navigate(`/login`);
										window.location.href =
											process.env['NX_APP_START'] +
											'login';
									}}
									style={{
										minWidth: 250,
										padding: '15px 15px',
										height: 'auto'
									}}
								>
									<FormattedMessage
										id="nav-bar.signin"
										defaultMessage="Iniciar sesión"
									/>
								</Button>
							</Space>
						)}
						{isAuth && (
							<DrawerMenu
								hideAddPropertyButton={appName === 'MAIN'}
								hideAddPropertyData={
									appName === 'MAIN' || hideAddPropertyData
								}
								onCloseDrawer={() =>
									setTimeout(() => toggleDrawer(false), 300)
								}
							/>
						)}
					</Drawer>
				</SectionButton>
			</Header>
		</Content>
	);
}

export default NavBar;

const Drawer = styled(AntDrawer)`
	.ant-drawer-body {
		padding: 24px 0;
	}
`;

const BtnBegin = styled(Button)`
	background-color: ${({ theme }) => theme.colors.primary.base};
	padding: 15px 15px;
	height: auto;
`;

const Header = styled(AntHeader)`
	display: grid;
	padding: 0rem 1.2rem;
	height: auto;
	grid-template-columns: 200px 1fr;
	height: 80px;
	line-height: 80px;
	@media ${minDevice.laptop} {
		grid-template-columns: 200px 1.5fr 1fr;
		/* padding: 0rem 2rem; */
	}
	${({ theme }) =>
		theme?.screens['xs'] &&
		css`
			padding: 0rem 15px;
		`}
`;

const Content = styled.div`
	position: fixed;
	border-bottom: 1px solid
		${({ theme }) =>
			theme?.colors?.gray?.lighten &&
			theme?.colors?.gray?.lighten['50%']};
	z-index: 3;
	width: 100%;
	&,
	& ${Header} {
		background-color: ${({ theme }) => theme.colors.containerBgColor};
	}
`;

const Section = styled.div`
	display: flex;
	align-items: center;
`;

const SectionMenu = styled(Section)`
	display: none;
	@media ${minDevice.laptop} {
		display: flex;
	}
`;

const SectionButton = styled(Section)`
	display: flex;
	@media ${minDevice.laptop} {
		display: none;
	}
`;

const StartButton = styled(BtnHospy)`
	border: 1px solid;
	color: ${({ theme }) => theme.colors.primary.base};
	display: flex;
	align-items: center;
	border-radius: 25px;
	padding: 15px;
	height: auto;
	&:hover {
		border: 1px solid ${({ theme }) => theme.colors.primary.base};
		color: ${({ theme }) => theme.colors.primary.base};
		background-color: ${({ theme }) =>
			theme?.colors?.primary?.lighten &&
			theme?.colors?.primary?.lighten['90%']};
	}

	${({ theme }) =>
		theme?.screens['xs'] &&
		css`
			border: none;
		`}
`;
