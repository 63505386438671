import { createContext, useContext, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom'; // Importamos useLocation
import Chatwoot from './components/chatwoot';

const ChatwootContext = createContext<any>({
	visible: true,
	setVisible: () => {}
});

export const ChatwootProvider: React.FC<{ children: React.ReactNode }> = ({
	children
}) => {
	const [visible, setVisible] = useState(true);
	const location = useLocation();

	useEffect(() => {
		setVisible(true);
	}, [location]);

	return (
		<ChatwootContext.Provider value={{ visible, setVisible }}>
			{children}
			{visible && <Chatwoot />}
		</ChatwootContext.Provider>
	);
};

export const useChatwoot = () => {
	const { visible, setVisible } = useContext(ChatwootContext);

	const hiddeChatWoot = () => {
		setVisible(false); // Oculta el chat
	};

	return {
		visible,
		hiddeChatWoot
	};
};
