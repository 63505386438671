import AccountMultiple from '@2fd/ant-design-icons/lib/AccountMultiple';
import CurrencyUsd from '@2fd/ant-design-icons/lib/CurrencyUsd';
import Eye from '@2fd/ant-design-icons/lib/Eye';
import HomeCity from '@2fd/ant-design-icons/lib/HomeCity'; //TODO: home_work.svg
//import { useIntercom } from 'react-use-intercom';

import {
	LogoutOutlined,
	SettingFilled,
	SettingOutlined
} from '@ant-design/icons';
import {
	logOut,
	setUserLoading,
	useAppDispatch,
	useAppSelector
} from '@hospy/store';
import { cognitoService } from '@hospy/util-auth';
import {
	Button,
	Col,
	Divider,
	Dropdown,
	Row,
	Space,
	Tooltip,
	Typography
} from 'antd';
import useSocialResponse from 'libs/shared-components/src/lib/pages/social-response/hook/use-social-response';
import React, { useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import SelectProperty from '../select-property';

export const LeftMenu = ({ theme }: { theme: string | undefined }) => {
	const dispatch = useAppDispatch();
	const navigate = useNavigate();

	const { loading2, addProperty, user } = useAppSelector(({ user }) => user);
	const { loadingProperty: loading } = useAppSelector(({ start }) => start);

	const menuItems = [
		{
			key: 'start',
			label: (
				<div
					style={{ minWidth: '6.25rem', textAlign: 'center' }}
					onClick={() => navigate('/')}
				>
					<FormattedMessage
						id="header.start"
						defaultMessage="Inicio"
					/>
				</div>
			)
		}
	];

	const exitViewUser = () => {
		localStorage.removeItem('userIdView');
		window.location.href = process.env['NX_APP_ADMIN'] + 'users';
	};

	return (
		<>
			{/* <Menu
				mode="horizontal"
				style={{ width: 140, marginRight: '2rem', border: 0 }}
				selectedKeys={['start']}
				items={menuItems}
			/> */}
			{/* {!((user?.properties || []).length > 0) && (
				<HospyButton
					type="primary"
					icon={<PlusOutlined />}
					size={'large'}
					onClick={() => dispatch(setShowPropertyModal(true))}
					loading={addProperty.loading === 'pending'}
				>
					<FormattedMessage
						id="header.createProperty"
						defaultMessage="Crear nuevo negocio"
					/>
				</HospyButton>
			)} */}

			{/* {(user?.properties || []).length > 0 && (
				<SelectProperty
					loading={Boolean(
						loading2 === 'pending' ||
							loading ||
							addProperty.loading === 'pending'
					)}
				/>
			)} */}
			{user?.userViewAdmin && (
				<Tooltip
					placement="bottom"
					title="Volver al módulo de usuarios"
				>
					<ButtonExitView
						onClick={exitViewUser}
						icon={<Eye />}
						size="large"
						type="text"
						shape="circle"
					/>
				</Tooltip>
			)}
		</>
	);
};

const ButtonExitView = styled(Button)`
	margin-left: 10px;
`;

interface RightMenuProps {
	style?: React.CSSProperties;
}

const RightMenuDefaultProps: RightMenuProps = {
	style: undefined
};

let processed = false;

const optionsAccount: any = {
	admin: {
		site: process.env['NX_APP_ADMIN'],
		options: [
			// 'wait-list',
			'profile',
			'manage-properties',
			'workteam',
			'exit'
		]
	},
	developer: {
		site: process.env['NX_APP_DEVELOPER'],
		options: ['profile', 'exit']
	},
	default: {
		options: [
			// 'wait-list',
			'profile',
			'manage-properties',
			'workteam',
			'exit',
			'suscription'
		]
	}
};

export const RightMenu = ({
	style
}: RightMenuProps | undefined = RightMenuDefaultProps) => {
	const navigate = useNavigate();
	const dispatch = useAppDispatch();

	const { user, isAuth } = useAppSelector(({ user }) => user);
	const [initials, setInitials] = useState('');

	// useEffect(() => {
	// 	// TODO: optimizar
	// 	if (isAuth && user && !processed) {
	// 		dispatch(fetchUserWaitList());
	// 		processed = true;
	// 	}
	// }, [isAuth, user?._id]);

	useEffect(() => {
		if (user) {
			const names = user?.name?.split(' ');
			if (names && names.length > 0) {
				let _initials = '';
				names.forEach((e: string) => {
					if (_initials.length === 2) return;
					_initials += e.charAt(0).toUpperCase();
				});
				setInitials(_initials);
			}
		}
	}, [user?.name]);

	const items = [
		{
			key: 'info',
			type: 'group',
			label: (
				<Row>
					<Col span={24}>
						<Typography.Text style={{ fontSize: 15 }}>
							{user?.name}
						</Typography.Text>
					</Col>
					<Col span={24}>
						<Typography.Text
							style={{ fontSize: 12, userSelect: 'text' }}
						>
							{user?.email}
						</Typography.Text>
					</Col>
				</Row>
			),
			children: [
				{
					type: 'divider'
				},
				// {
				// 	key: 'wait-list',
				// 	label: (
				// 		<AuxMenuItem onClick={() => navigate('/wait-list')}>
				// 			<PlaylistCheck />
				// 			<span style={{ marginLeft: 5 }}>
				// 				<FormattedMessage
				// 					id="header.myWaitList"
				// 					defaultMessage="Mi lista de espera"
				// 				/>
				// 			</span>
				// 		</AuxMenuItem>
				// 	)
				// },
				{
					key: 'suscription',
					label: (
						<AuxMenuItem onClick={() => navigate('/subscriptions')}>
							<CurrencyUsd />
							<span style={{ marginLeft: 5 }}>
								<FormattedMessage
									id="header.mySuscription"
									defaultMessage="Mi suscripticón"
								/>
							</span>
						</AuxMenuItem>
					)
				},
				{
					key: 'profile',
					label: (
						<AuxMenuItem onClick={() => navigate('/account')}>
							<SettingOutlined />
							<span style={{ marginLeft: 5 }}>
								<FormattedMessage
									id="header.configurateProfile"
									defaultMessage="Configurar perfil"
								/>
							</span>
						</AuxMenuItem>
					)
				},
				{
					key: 'manage-properties',
					label: (
						<AuxMenuItem
							onClick={() => navigate('/manage-properties')}
						>
							<HomeCity />
							<span style={{ marginLeft: 5 }}>
								<FormattedMessage
									id="header.manageProperties"
									defaultMessage="Gestionar negocios"
								/>
							</span>
						</AuxMenuItem>
					)
				},
				{
					key: 'workteam',
					label: (
						<AuxMenuItem onClick={() => navigate('/workteam')}>
							<AccountMultiple />
							<span style={{ marginLeft: 5 }}>
								<FormattedMessage
									id="header.workteam"
									defaultMessage="Mi equipo de trabajo"
								/>
							</span>
						</AuxMenuItem>
					)
				},
				{
					key: 'exit',
					label: <ButtonExit />
				}
			]
		}
	];

	let typeMenu: any = Object.keys(optionsAccount).find((item: string) =>
		optionsAccount[item]?.site?.includes(window.location.origin)
	);

	if (!typeMenu) typeMenu = 'default';

	if (items[0]) {
		items[0].children = items[0].children.filter((option) => {
			return (
				option.type === 'divider' ||
				optionsAccount[typeMenu].options.includes(option.key)
			);
		});
	}

	return (
		<Dropdown
			menu={{ items }}
			trigger={['click']}
			placement="bottomRight"
			getPopupContainer={(e) => e}
		>
			<AuxMenu
				type="primary"
				shape="circle"
				style={{ ...style, padding: 0 }}
			>
				{initials}
			</AuxMenu>
		</Dropdown>
	);
};

export const DrawerMenuDivider = styled(Divider)`
	margin: 1rem 0 !important;
`;

export const DrawerMenu = ({
	onCloseDrawer,
	hideAddPropertyButton,
	appName,
	hideAddPropertyData,
	menu
}: {
	onCloseDrawer: any | undefined;
	hideAddPropertyButton?: boolean;
	hideAddPropertyData?: boolean;
	appName?: string;
	menu?: React.ReactElement;
}) => {
	const navigate = useNavigate();
	return (
		<>
			<div style={{ textAlign: 'center' }}>
				<Button
					type="text"
					block
					onClick={() => {
						if (onCloseDrawer) onCloseDrawer();
						navigate('/');
					}}
				>
					<FormattedMessage
						id="header.start"
						defaultMessage="Inicio"
					/>
				</Button>
				{/* <DrawerMenuDivider />
				<MenuButton
					buttonText={'Mi lista de espera'}
					buttonProps={{
						block: true,
						icon: <PlaylistCheck />,
						onClick: () => {
							navigate('/wait-list');
							onCloseDrawer();
						}
					}}
				/> */}
				<DrawerMenuDivider />
				<MenuButton
					buttonText={'Mi suscripción'}
					buttonProps={{
						block: true,
						icon: <CurrencyUsd />,
						onClick: () => {
							navigate('/subscriptions');
							onCloseDrawer();
						}
					}}
				/>
				<DrawerMenuDivider />
				<MenuButton
					buttonText={'Configurar perfil'}
					buttonProps={{
						block: true,
						icon: <SettingFilled />,
						onClick: () => {
							navigate('/account');
							onCloseDrawer();
						}
					}}
				/>
				<DrawerMenuDivider />
				<MenuButton
					buttonText={'Gestionar negocios'}
					buttonProps={{
						block: true,
						icon: <HomeCity />,
						onClick: () => {
							navigate('/manage-properties');
							onCloseDrawer();
						}
					}}
				/>
				<DrawerMenuDivider />
				<MenuButton
					buttonText={'Mi equipo de trabajo'}
					buttonProps={{
						block: true,
						icon: <AccountMultiple />,
						onClick: () => {
							navigate('/workteam');
							onCloseDrawer();
						}
					}}
				/>
				<DrawerMenuDivider />
				{menu}
				<ButtonExit style={{ minWidth: 'auto' }} />
				{!hideAddPropertyData && (
					<Space direction="vertical" style={{ display: 'flex' }}>
						<SelectProperty
							appName={appName}
							onCloseDrawer={onCloseDrawer}
							hideAddPropertyButton={hideAddPropertyButton}
						/>
					</Space>
				)}
			</div>
		</>
	);
};

/* eslint-disable-next-line */
interface MenuButtonProps {
	tooltip?: string | object;
	buttonProps?: any;
	buttonText?: string | undefined;
	style?: React.CSSProperties;
	onClickPropagate?(): void;
}

// const ButtonSwithTheme = (props: MenuButtonProps) => {
// 	const currentTheme = 'light';
// 	const [isDarkMode, setIsDarkMode] = useState(false);

// 	const toggleTheme = (isChecked: boolean) => {
// 		setIsDarkMode(isChecked);
// 		const darkModeToggleEvent = new CustomEvent("drakModeToggle", { detail: null });
// 		dispatchEvent(darkModeToggleEvent);
// 	};

// 	const icon = currentTheme === 'light' ?
// 		<NightsStayIcon style={{ marginTop: 5 }} /> :
// 		<LightModeIcon style={{ marginTop: 5 }} />

// 	return (
// 		<MenuButton
// 			{...props}
// 			buttonProps={{
// 				onClick: () => {
// 					if (props.onClickPropagate) props.onClickPropagate()
// 					toggleTheme(!isDarkMode)
// 				},
// 				icon,
// 				...props.buttonProps
// 			}}
// 		/>
// 	)
// }

const ButtonExit = ({ style }: { style?: React.CSSProperties }) => {
	// const { switcher, themes, status } = useThemeSwitcher();
	const { appName } = useAppSelector(({ user }) => user);
	const { user } = useAppSelector((state: any) => state.user);
	const { logOutSocial } = useSocialResponse();
	const dispatch = useAppDispatch();
	const [processLogout, setProcessLogout] = useState(false);

	const navigate = useNavigate();
	//const { shutdown, boot } = useIntercom();

	useEffect(() => {
		const fetch = async () => {
			if (user) {
				dispatch(setUserLoading('pending'));
				const isGoogleUser =
					localStorage.getItem('type_login') === 'GOOGLE';
				if (isGoogleUser) {
					dispatch(setUserLoading('idle'));
					logOutSocial();
				} else {
					dispatch(setUserLoading('idle'));
					await cognitoService.signOut();
					dispatch(logOut());

					if (appName === 'MAIN') {
						navigate('/', { replace: true });
					} else {
						navigate('/login', { replace: true });
					}
					// shutdown();
					// boot({
					// 	name: undefined,
					// 	userId: undefined,
					// 	email: undefined,
					// 	company: undefined
					// });
				}
			}
		};
		if (processLogout) fetch();
		return () => setProcessLogout(false);
	}, [processLogout, user?.email]);

	const exit = () => {
		setProcessLogout(true);
	};
	return (
		<AuxMenuItem onClick={exit} style={{ color: 'red', ...style }}>
			<LogoutOutlined />
			<span style={{ marginLeft: 5 }}>
				<FormattedMessage id="header.logOut" defaultMessage="Salir" />
			</span>
		</AuxMenuItem>
	);
};

const MenuButton = (props: MenuButtonProps) => {
	const btn = (
		<Button
			type="text"
			size="large"
			{...props?.buttonProps}
			style={{ ...props?.style }}
		>
			{props?.buttonText}
		</Button>
	);

	if (props.tooltip) {
		let toolTipProps: any = {
			placement: 'bottom'
		};

		if (typeof props.tooltip === 'string')
			toolTipProps.title = props.tooltip;
		else toolTipProps = { ...toolTipProps, ...props.tooltip };

		return <Tooltip {...toolTipProps}>{btn}</Tooltip>;
	}
	return btn;
};

const AuxMenu = styled(Button)`
	min-width: ${({ theme }) => theme.input.height.small}px;
	width: ${({ theme }) => theme.input.height.small}px;
	height: ${({ theme }) => theme.input.height.small}px;
	padding: 0 !important;
	display: flex !important;
	justify-content: center !important;
	align-items: center !important;
	background-color: ${({ theme }) => theme.colors.primary.base};
	&:hover,
	&:focus,
	&:active {
		background-color: ${({ theme }) =>
			theme.colors.primary.lighten?.['20%']};
	}
`;

const AuxMenuItem = styled.div`
	min-width: 10rem;
	padding: 8px 0px;
`;
