import { Image } from 'antd';

import IconColor from './../../../../assets/images/icon.png';
import IconWhite from './../../../../assets/images/icon_white.png';

import logo24 from './../../../../assets/logos/logo-24.png';
import logoDark from './../../../../assets/logos/logo-hospy-dark.png';
import logoLight from './../../../../assets/logos/logo-hospy-light.png';

export function LogoIcon(props: any) {
	const currentTheme = props?.theme || 'light';
	let logo = currentTheme === 'light' ? logoLight : logoDark;
	if (props?.logoType === 'christmas') {
		logo = logo24;
	}

	return <Image width={120} {...props} preview={false} src={logo} />;
}

export const HospyIcon = (props: any) => (
	<Image
		{...props}
		preview={false}
		src={props.color ? IconColor : IconWhite}
	/>
);
