import { Async, InitialState } from './interface';

const initialAsync: Async = {
	loading: 'idle',
	error: undefined,
	data: undefined
};

export const initialState: InitialState = {
	reservations: initialAsync,
	reservationsInvoices: initialAsync,
	invoice: initialAsync,
	validateReservationDocument: initialAsync
};
