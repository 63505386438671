/* eslint-disable @typescript-eslint/no-explicit-any */
import {
	fetchCreateDiscounts,
	fetchDeleteDiscounts,
	fetchDeletePaymentMethod,
	fetchGetCustomer,
	fetchGetDiscounts,
	fetchGetIntentStripe,
	fetchGetInvoices,
	fetchGetPaymentMethods,
	fetchGetSubscription,
	fetchGetSubscriptionToRenewPlan,
	fetchSubscriptionsToRenew,
	fetchUpdateCustomer,
	fetchUpdateCustomerStripe
} from '@hospy/util-api';
import { createAsyncThunk } from '@reduxjs/toolkit';

export const getSubscription = createAsyncThunk(
	'subscriptions/getSubscription',
	async (payload, { rejectWithValue }) => {
		try {
			const { data } = await fetchGetSubscription();
			if (!data) return rejectWithValue('Error al cargar la suscripción');
			return data;
		} catch (error: any) {
			return rejectWithValue('Error al cargar la suscripción');
		}
	}
);

export const getSubscription2025 = createAsyncThunk(
	'subscriptions/getSubscription2025',
	async ({ propertyId }: { propertyId?: string }, { rejectWithValue }) => {
		try {
			const { data } = await fetchGetSubscriptionToRenewPlan(propertyId);
			if (!data) return rejectWithValue('Error al cargar la suscripción');
			return data;
		} catch (error: any) {
			return rejectWithValue('Error al cargar la suscripción');
		}
	}
);

export const getCustomer = createAsyncThunk(
	'subscriptions/getCustomer',
	async (payload, { rejectWithValue }) => {
		try {
			const { data } = await fetchGetCustomer();
			if (!data) return rejectWithValue('Error al cargar el cliente');
			return data;
		} catch (error: any) {
			return rejectWithValue('Error al cargar el cliente');
		}
	}
);

export const updateCustomer = createAsyncThunk(
	'subscriptions/updateCustomer',
	async (payload: any, { rejectWithValue }) => {
		try {
			const { data } = await fetchUpdateCustomer(payload);
			if (!data) return rejectWithValue('Error al actualizar el cliente');
			return data;
		} catch (error: any) {
			return rejectWithValue('Error al actualizar el cliente');
		}
	}
);

export const getIntentStripe = createAsyncThunk(
	'subscriptions/getIntentStripe',
	async (payload, { rejectWithValue }) => {
		try {
			const { data } = await fetchGetIntentStripe();
			if (!data) return rejectWithValue('Error al intent stripe');
			return data;
		} catch (error: any) {
			return rejectWithValue('Error al intent stripe');
		}
	}
);

export const getPaymentMethods = createAsyncThunk(
	'subscriptions/getPaymentMethods',
	async (payload, { rejectWithValue }) => {
		try {
			const { data } = await fetchGetPaymentMethods();
			if (!data)
				return rejectWithValue('Error al obtener métodos de pago');
			return data;
		} catch (error: any) {
			return rejectWithValue('Error al obtener métodos de pago');
		}
	}
);

export const updateCustomerStripe = createAsyncThunk(
	'subscriptions/updateCustomerStripe',
	async (payload: any, { rejectWithValue }) => {
		try {
			const { data } = await fetchUpdateCustomerStripe(payload);
			if (!data)
				return rejectWithValue('Error al obtener métodos de pago');
			return data;
		} catch (error: any) {
			return rejectWithValue('Error al obtener métodos de pago');
		}
	}
);

export const deletePaymentMethod = createAsyncThunk(
	'subscriptions/deletePaymentMethod',
	async (payload: any, { rejectWithValue }) => {
		try {
			const { data } = await fetchDeletePaymentMethod(payload);
			if (!data)
				return rejectWithValue('Error al obtener métodos de pago');
			return data;
		} catch (error: any) {
			return rejectWithValue('Error al obtener métodos de pago');
		}
	}
);

export const getInvoices = createAsyncThunk(
	'subscriptions/getInvoices',
	async (payload: any, { rejectWithValue }) => {
		try {
			const { data } = await fetchGetInvoices(payload);
			if (!data) return rejectWithValue('Error al obtener facturas');
			return data;
		} catch (error: any) {
			return rejectWithValue('Error al obtener facturas');
		}
	}
);

export const getLastInvoice = createAsyncThunk(
	'subscriptions/getLastInvoice',
	async (payload: any, { rejectWithValue }) => {
		try {
			const { data } = await fetchGetInvoices(payload);
			if (!data) return rejectWithValue('Error al obtener facturas');
			return data;
		} catch (error: any) {
			return rejectWithValue('Error al obtener facturas');
		}
	}
);

export const getDiscounts = createAsyncThunk(
	'subscriptions/getDiscounts',
	async (payload: any, { rejectWithValue }) => {
		try {
			const data = await fetchGetDiscounts(payload);
			if (!data) return rejectWithValue('Error al obtener descuentos');
			return data;
		} catch (error: any) {
			return rejectWithValue('Error al obtener descuentos');
		}
	}
);

export const createDiscounts = createAsyncThunk(
	'subscriptions/createDiscounts',
	async (payload: any, { rejectWithValue }) => {
		try {
			const { data, errors } = await fetchCreateDiscounts(payload);
			if (!data)
				return rejectWithValue(
					errors[0] ? errors[0].message : 'Error al crear descuentos'
				);
			return data;
		} catch (error: any) {
			console.error(error);
			return rejectWithValue('Error al crear descuentos');
		}
	}
);

export const deleteDiscounts = createAsyncThunk(
	'subscriptions/deleteDiscounts',
	async (payload: any, { rejectWithValue }) => {
		try {
			const { data, errors } = await fetchDeleteDiscounts(payload);
			if (!data)
				return rejectWithValue(
					errors[0]
						? errors[0].message
						: 'Error al eliminar descuentos'
				);
			return data;
		} catch (error: any) {
			return rejectWithValue('Error al eliminar descuentos');
		}
	}
);

export const getSubscriptionsToRenew = createAsyncThunk(
	'subscriptions/getSubscriptionsToRenew',
	async (propertyId: string | undefined, { rejectWithValue }) => {
		try {
			const data = await fetchSubscriptionsToRenew(propertyId);
			if (!data) return rejectWithValue('Error al obtener suscripción');
			return data;
		} catch (error: any) {
			return rejectWithValue('Error al obtener suscripción');
		}
	}
);

export const getSubscriptionsToRenewPlan = createAsyncThunk(
	'subscriptions/getSubscriptionsToRenewPlan',
	async (propertyId: string | undefined, { rejectWithValue }) => {
		try {
			const data = await fetchGetSubscriptionToRenewPlan(propertyId);
			if (!data) return rejectWithValue('Error al obtener suscripción');
			return data;
		} catch (error: any) {
			return rejectWithValue('Error al obtener suscripción');
		}
	}
);
