/* eslint-disable react/jsx-no-useless-fragment */
/* eslint-disable @typescript-eslint/no-explicit-any */
import QuestionCircleOutlined from '@ant-design/icons/QuestionCircleOutlined';
import { Button as ButtonHospy } from '@hospy/hospy-ui';
import { APPS, useAppSelector } from '@hospy/store';
import { Tooltip } from 'antd';
import { Link, useLocation } from 'react-router-dom';
import styled, { useTheme } from 'styled-components';
import { HospyIcon } from '../../atoms/icons';
import AppSelectApp from '../app-select-app';
import { RightMenu } from '../nav-bar/nav-bar-menu';

interface AppSideBarProps {
	readonly title: string;
	readonly menu?: React.ReactElement;
	readonly hideAppButton?: boolean;
}

const AppSideBar: React.FC<AppSideBarProps> = ({
	title,
	menu,
	hideAppButton
}) => {
	const theme: any = useTheme();

	const { pathname } = useLocation();
	const { appData } = useAppSelector(({ common }) => common);

	if (theme.screens.xs && appData?._id === APPS.CHECKIN_HOST) {
		return (
			<>
				<MobileContainer>
					<div style={{ paddingInline: 15 }}>{menu}</div>
				</MobileContainer>
			</>
		);
	}

	return (
		<Container>
			<SectionHeader>
				<Link
					to={
						[
							'/onboarding',
							'/listing/required-config',
							'/show-new-rates'
						].includes(pathname)
							? pathname
							: '/'
					}
				>
					<HospyIcon style={{ width: 30, marginBottom: 26 }} />
				</Link>
				<SectionHeaderMenu>{menu}</SectionHeaderMenu>
			</SectionHeader>
			<div
				style={{
					display: 'flex',
					flexDirection: 'column',
					alignItems: 'center'
				}}
			>
				<div
					style={{
						marginBottom: 30,
						display: 'flex',
						flexDirection: 'column',
						alignItems: 'center',
						justifyContent: 'center'
					}}
				>
					{appData?.knowledgeBaseUrl && (
						<Tooltip title="Manual de ayuda" placement="right">
							<Button
								icon={<QuestionCircleOutlined />}
								type="text"
								shape="circle"
								target="_blank"
								href={appData.knowledgeBaseUrl}
								size="middle"
							/>
						</Tooltip>
					)}
					{!hideAppButton && <AppSelectApp title={title} />}
				</div>
				<RightMenu style={{ borderColor: 'white' }} />
			</div>
		</Container>
	);
};

export default AppSideBar;

const MobileContainer = styled.div`
	background-color: ${({ theme }) => theme.colors.primary.base};
	position: fixed;
	width: 100%;
	bottom: 0;
	left: 0;
	right: 0;
	z-index: 4;
`;

const Container = styled.div`
	width: 80px;
	position: fixed;
	left: 0;
	top: 0;
	background-color: ${({ theme }) => theme.colors.primary.base};
	z-index: 2;
	height: 100vh;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	align-items: center;
	padding: 20px 0;
`;

const SectionHeader = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	width: 100%;
`;
const SectionHeaderMenu = styled.div`
	width: 100%;
`;

const Button = styled(ButtonHospy)`
	display: flex;
	justify-content: center;
	align-items: center;
	padding: 0;
	& > .anticon {
		line-height: 0;
		font-size: 30px;
		color: #fff;
	}
`;
