import { Async } from '../common/common.interfaces';
import { InvoiceDetailSection, SiigoConnectInitialState } from './interface';

export const siigoErrorDictionary = {
	timed_out:
		'Siigo está tardando en responder. Por favor intenta reenviar la factura en unos minutos.',
	invalid_amount:
		'Siigo ha respondido que el valor de lo totales es inválido. Por favor contacte con soporte de Hospy.',
	invalid_total_payments:
		'Siigo ha respondido que el valor de lo totales es inválido. Por favor contacte con soporte de Hospy.',
	invalid_cost_center:
		'El cento de costo enviado para la factura no es válido en Siigo. Por favor revise las configuraciones y nuestros manuales de ayuda.',
	invalid_date:
		'La fecha que agregaste para la factura no es aceptada por Siigo',
	invalid_payment:
		'Una de las formas de pago usadas en la factura no está sincronizada con Siigo. Por favor revise las configuraciones y nuestros manuales de ayuda.',
	documents_service:
		'La comunicación con Siigo no se encuentra disponible debido a fallas en Siigo NUBE. Intenta más tarde.',
	not_found:
		'La comunicación con Siigo no se encuentra disponible debido a fallas en Siigo NUBE. Intenta más tarde.',
	unhandled_error:
		'La comunicación con Siigo no se encuentra disponible debido a fallas en Siigo NUBE. Intenta más tarde.',
	'invalid-json':
		'La comunicación con Siigo no se encuentra disponible debido a fallas en Siigo NUBE. Intenta más tarde.',
	'<!DOCTYPE':
		'La comunicación con Siigo no se encuentra disponible debido a fallas en Siigo NUBE. Intenta más tarde.',
	'disconnect/reset':
		'La comunicación con Siigo no se encuentra disponible debido a fallas en Siigo NUBE. Intenta más tarde.',
	ServiceUnavailable:
		'La comunicación con Siigo no se encuentra disponible debido a fallas en Siigo NUBE. Intenta más tarde.'
};

export const INVOICE_DETAIL_SECTIONS: InvoiceDetailSection[] = [
	{
		key: 'customer',
		hasError: false,
		fields: {
			name: {
				hasError: false
			},
			documentType: {
				hasError: false
			},
			documentNumber: {
				hasError: false
			},
			email: {
				hasError: false
			},
			phone: {
				hasError: false
			},
			address: {
				hasError: false
			},
			country: {
				hasError: false
			},
			state: {
				hasError: false
			},
			city: {
				hasError: false
			}
		}
	},
	{
		key: 'items',
		hasError: false,
		fields: {}
	},
	{
		key: 'payments',
		hasError: false,
		fields: {}
	}
];

export const INVOICE_OTA_DETAIL_SECTIONS: InvoiceDetailSection[] = [
	{
		key: 'customer',
		hasError: false,
		fields: {
			name: {
				hasError: false
			},
			documentType: {
				hasError: false
			},
			documentNumber: {
				hasError: false
			},
			email: {
				hasError: false
			},
			phone: {
				hasError: false
			},
			address: {
				hasError: false
			},
			country: {
				hasError: false
			},
			state: {
				hasError: false
			},
			city: {
				hasError: false
			}
		}
	},
	{
		key: 'items',
		hasError: false,
		fields: {}
	},
	{
		key: 'payments',
		hasError: false,
		fields: {}
	},
	{
		key: 'costCenter',
		hasError: false,
		fields: {}
	},
	{
		key: 'paymentWay',
		hasError: false,
		fields: {}
	},
	{
		key: 'documentBill',
		hasError: false,
		fields: {}
	}
];

const initialAsync: Async = {
	loading: 'idle',
	error: undefined,
	data: undefined
};

export const siigoInitialState: SiigoConnectInitialState = {
	createFieldsMapping: initialAsync,
	paymentMethodsPMS: initialAsync,
	paymentMethodsFinance: initialAsync,
	documentTypes: initialAsync,
	costCenter: initialAsync,
	taxes: initialAsync,
	destinyTaxes: initialAsync,
	originListingTypes: initialAsync,
	destinyListingTypes: initialAsync,
	originProducts: initialAsync,
	destinyProducts: initialAsync,
	reservations: { ...initialAsync, allReservations: [] },
	internalAccounts: initialAsync,
	invoice: initialAsync,
	updatedInvoice: initialAsync,
	deletedInvoice: initialAsync,
	customersDocumentTypes: initialAsync,
	users: initialAsync,
	stamp: initialAsync,
	getStamp: initialAsync,
	invoiceValidation: INVOICE_DETAIL_SECTIONS,
	stampCreditNote: initialAsync,
	stampErrors: initialAsync,
	updateStampInvoice: {
		loading: 'idle'
	},
	invoiceSnapshot: null,
	retryAdvancePayment: initialAsync,
	retryJournal: initialAsync,
	addFavoriteInternalAccount: initialAsync,
	removeFavoriteInternalAccount: initialAsync
};

export const CREATE_CUSTOMER_FIELD_MESSAGE = {
	name: { key: 'name', name: 'Nombre' },
	documentNumber: { key: 'documentNumber', name: 'Número de documento' },
	documentType: { key: 'documentType', name: 'Tipo de documento' },
	email: { key: 'email', name: 'Correo' },
	phone: { key: 'phone', name: 'Teléfono' },
	city: { key: 'city', name: 'Ciudad' },
	state: { key: 'state', name: 'Estado' },
	country: { key: 'country', name: 'País' },
	address: { key: 'address', name: 'Dirección' }
};

export const SiigoConnectSliceName = 'siigo-connect';

export const SIIGO_DEFAULT_TAX_AMOUNT = 19;
