import { maxDevice } from '@hospy/feature/devices';
import { AccountForm, SocialMediaButton } from '@hospy/shared-components';
import { Button, Col, Divider, Form, Input, Row } from 'antd';
import { FormattedMessage, useIntl } from 'react-intl';
import { Link, useLocation } from 'react-router-dom';
import styled from 'styled-components';
import useSocialResponse from '../../social-response/hook/use-social-response';
import { IUseLogin } from '../hook/use-login';
import { APPS } from '../../../../../../store/src';

const LoginForm = (props: IUseLogin) => {
	const intl = useIntl();
	const location = useLocation();
	const { processAccessWithGoogle } = useSocialResponse();

	const isRegister =
		!process.env['NX_APP_ADMIN']?.includes(window.location.origin) &&
		!process.env['NX_APP_DEVELOPER']?.includes(window.location.origin);

	const onFinish = ({ username, password }: any) => {
		props.onSubmit({ username, password });
	};

	const handlerOnClick = async () => processAccessWithGoogle('login');

	return (
		<AccountForm.Container>
			<AccountForm.Content
				title={intl.formatMessage({
					id: 'login.loginTitle',
					description: 'Login title',
					defaultMessage: 'Inicia sesión en tu cuenta'
				})}
			>
				<Form onFinish={onFinish} autoComplete="off">
					<Form.Item
						name="username"
						rules={[
							{
								required: true,
								message: intl.formatMessage({
									id: 'form.requiredField',
									description: 'Form required field',
									defaultMessage: 'Campo requerido'
								})
							},
							{
								type: 'email',
								message: 'No es un email valido'
							},
						]}
					>
						<Input
							size="large"
							placeholder={intl.formatMessage({
								id: 'form.inputEmail.placeholder',
								description: 'Form input email placeholder',
								defaultMessage: 'Ingresa tu email'
							})}
							disabled={props.loading}
						/>
					</Form.Item>
					<Form.Item
						name="password"
						rules={[
							{
								required: true,
								message: intl.formatMessage({
									id: 'form.requiredField',
									description: 'Form required field',
									defaultMessage: 'Campo requerido'
								})
							}
						]}
					>
						<Input.Password
							size="large"
							placeholder={intl.formatMessage({
								id: 'form.inputPassword.placeholder',
								description: 'Form input password placeholder',
								defaultMessage: 'Contraseña'
							})}
							disabled={props.loading}
						/>
					</Form.Item>
					<Form.Item>
						<Button
							htmlType="submit"
							size="large"
							type="primary"
							block
							loading={props.loading}
						>
							<FormattedMessage
								id="login.continue"
								defaultMessage="Continuar"
							/>
						</Button>
					</Form.Item>
				</Form>
				<Row justify="center">
					<Col span={24}>
						<Divider>
							<FormattedMessage
								id="login.orPreference"
								defaultMessage="O si prefieres:"
							/>
						</Divider>
					</Col>
					<Col span={24}>
						<SocialMediaButton
							onClick={handlerOnClick}
							type="GOOGLE"
							label={intl.formatMessage({
								id: 'login.loginGoogle',
								description: 'Form login google',
								defaultMessage: 'Ingresar con Google'
							})}
						/>
					</Col>
					{/* {appData?.partnerIntegrations.map((e) => (
						<Col span={24}>
							<SocialMediaButton
								type={e?.name}
								label={intl.formatMessage(
									{
										id: 'login.loginApp',
										description: 'Form login app',
										defaultMessage: 'Tu cuenta de {app}'
									},
									{
										app: capitalize(e?.name)
									}
								)}
							/>
						</Col>
					))} */}
					{props.appId !== APPS.SCAN_ID && (
						<Col style={{ marginTop: 10 }}>
							<span style={{ fontSize: '.8rem' }}>
								<Link to={'/recover-password'} replace>
									<FormattedMessage
										id="login.forgotPassword"
										defaultMessage="¿Olvidaste tus accesos?"
									/>
								</Link>
							</span>
						</Col>
					)}
					{/* {props.appId !== APPS.SCAN_ID && isRegister && (
						<Col>
							<span style={{ fontSize: '.8rem' }}>
								<FormattedMessage
									id="login.ifYouDontHaveAccount"
									defaultMessage="Si no tienes una cuenta"
								/>{' '}
								<Link
									to={'/register'}
									state={{ ...location.state }}
								>
									<FormattedMessage
										id="login.registerHere"
										defaultMessage="regístrate aquí"
									/>
								</Link>
							</span>
						</Col>
					)} */}
				</Row>
				{props.appInfoSection}
			</AccountForm.Content>

			<div style={{ textAlign: 'center', color: '#fff' }}>
				{props.privacyPoliciesUrl && (
					<ButtonTermsConditions
						type="link"
						target="_blank"
						href={props.privacyPoliciesUrl}
					>
						<FormattedMessage
							id="login.policyAndPrivacy"
							defaultMessage="Política de privacidad"
						/>
					</ButtonTermsConditions>
				)}
				{props.privacyPoliciesUrl &&
					props.termsAndConditionsUrl &&
					' - '}
				{props.termsAndConditionsUrl && (
					<ButtonTermsConditions
						type="link"
						target="_blank"
						href={props.termsAndConditionsUrl}
					>
						<FormattedMessage
							id="login.termsAndConditions"
							defaultMessage="Términos y condiciones"
						/>
					</ButtonTermsConditions>
				)}
			</div>
		</AccountForm.Container>
	);
};

export default LoginForm;

const ButtonTermsConditions = styled(Button)`
	color: #fff;
	font-size: 0.8rem;
	@media ${maxDevice.mobileL} {
		color: #3494e0;
	}
`;

const LinkButton = styled(Link)`
	font-size: 0.8rem;
`;
