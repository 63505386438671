import { ISubscriptionsState } from './interfaces';

const initialAsync = {
	loading: 'idle',
	error: undefined,
	data: undefined
};

export const initialState = {
	subscription: initialAsync,
	subscription2025: initialAsync,
	customer: initialAsync,
	updateCustomer: initialAsync,
	intentStripe: initialAsync,
	paymentMethods: initialAsync,
	deletePaymentMethod: initialAsync,
	updateCustomerStripe: initialAsync,
	lastInvoice: initialAsync,
	invoices: initialAsync,
	discounts: initialAsync,
	createDiscount: initialAsync,
	deleteDiscount: initialAsync,
	subscriptionToRenew: initialAsync,
	subscriptionToRenewPlan: initialAsync,
} as ISubscriptionsState;
