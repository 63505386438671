import { LoadingAppView } from '@hospy/shared-components';
import { useAppSelector } from '@hospy/store';
import { cognitoService } from '@hospy/util-auth';
import { AnimatePresence } from 'framer-motion';
import React, { useEffect } from 'react';
import AppRoutes from '../routes/app-route';
import { useChatwoot } from '@hospy/shared-components';

const App = () => {
	const { isAuth, user } = useAppSelector(({ user }) => user);
	const {  hiddeChatWoot} = useChatwoot()
	useEffect(() => {
		if (
			user &&
			process.env['NX_APP_ADMIN']?.includes(window.location.origin)
		)
			validateAdmin();
	}, [user]);
	hiddeChatWoot()
	const validateAdmin = async () => {
		const isAdmin = await cognitoService.isRolUser({
			Name: 'custom:admin-app-acl',
			Value: 'admin'
		});
		if (!isAdmin) window.location.href = process.env['NX_APP_SPA'] + '';
	};

	if (isAuth && !user) return <LoadingAppView />;

	return (
		<React.Suspense>
			<AnimatePresence>
				<AppRoutes />
			</AnimatePresence>
		</React.Suspense>
	);
};

export default App;
