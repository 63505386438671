import { useState, useRef, useEffect } from 'react';
import styled from 'styled-components';
import { Button, Typography } from 'antd';
import { YoutubeFilled, RightOutlined } from '@ant-design/icons';
import { Button as Btn, Icon } from '@hospy/hospy-ui';
import { motion, AnimatePresence } from 'framer-motion';
import { useAppSelector } from '@hospy/store';

const DEFAULT_MESSAGE = '¡Hola! ¿Cómo podemos ayudarte?';

const Chatwoot = () => {
	const [isOpen, setIsOpen] = useState(false);
	const chatRef = useRef<HTMLDivElement>(null);

	const [message, setMessage] = useState(DEFAULT_MESSAGE);

	const { user , property} = useAppSelector(({ user }) => user);
	const currentProperty = user?.properties?.find(
		(e) => e._id === user?.currentProperty || ''
	);
	useEffect(() => {
		const propertyCountry = property?.location?.country?.iso
		if (propertyCountry) {

			setMessage(
				propertyCountry !== 'BR'? `Hola, escribo de ${currentProperty?.name || ''}, quisiera ayuda con:\n`:`Olá, escrevo de ${currentProperty?.name || ''}, gostaria de ajuda com:\n` 
			);
		} else {
			setMessage(DEFAULT_MESSAGE);
		}
	}, [currentProperty, property?.location?.country?.iso]);

 

	const handleClickOutside = (event: MouseEvent) => {
		if (
			chatRef.current &&
			!chatRef.current.contains(event.target as Node)
		) {
			setIsOpen(false);
		}
	};

	useEffect(() => {
		document.addEventListener('mousedown', handleClickOutside);
		return () => {
			document.removeEventListener('mousedown', handleClickOutside);
		};
	}, []);


 
 
	return (
		<ChatContainer ref={chatRef}>
			 

			<div
				style={{
					display: 'flex',
					width: '100%',
					justifyContent: 'end',
					paddingTop: '22px'
				}}
			>
				<ChatButton
					type="primary"
					shape="circle"
					icon={
						<Icon
							materialOutlined="whatsapp-bold"
							style={{
								fontSize: '25px',
								color: 'white',
								marginTop: '2px',
								marginRight: '2px'
							}}
						/>
					}
					onClick={(e) => {
						e.stopPropagation();
							window.open(
								`https://api.whatsapp.com/send?phone=+573054529738&text=${encodeURI(
									message
								)}`,
								'_blank'
							)
						
					}}
					size="large"
				/>
			</div>
		</ChatContainer>
	);
};

export default Chatwoot;
 

const ChatContainer = styled.div`
	position: fixed;
	bottom: 25px;
	right: 15px;
	display: flex;
	flex-direction: column;
	align-items: flex-end;
	transition: all 0.3s ease;
	z-index: 10;
`;

 

 

const ChatButton = styled(Btn)`
	background-color: #25d366 !important;
	box-shadow: ${({ theme }) =>
		theme.boxShadow.level2('rgba(37,211,102, 0.5)')} !important;
	transition: background-color 0.3s ease, box-shadow 0.3s ease;
	border: none;
	& svg {
		font-size: 20px;
	}

	:hover,
	:active,
	:focus {
		background-color: #25d366 !important;
		border: none;
	}
`;

