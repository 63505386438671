import { createSlice } from '@reduxjs/toolkit';
import { initialState } from './constants';
import {
	getReservations,
	getReservationsInvoices,
	ThunkValidateReservationDocument
} from './thunks';

export const partnerReservationSlice = createSlice({
	name: 'partner-reservations',
	initialState,
	reducers: {
		setReservationsInvoices: (state, action) => {
			state.reservationsInvoices.data = {
				...state.reservationsInvoices.data,
				data: action.payload
			};
		}
	},
	extraReducers: (builder) => {
		builder
			.addCase(getReservations.pending, (state) => {
				state.reservations.loading = 'pending';
			})
			.addCase(getReservations.rejected, (state, payload) => {
				state.reservations.loading = 'idle';
				state.reservations.error = payload;
			})
			.addCase(getReservations.fulfilled, (state, { payload }) => {
				state.reservations.data = payload;
				state.reservations.loading = 'succeeded';
			})
			.addCase(getReservationsInvoices.pending, (state) => {
				state.reservationsInvoices.loading = 'pending';
			})
			.addCase(getReservationsInvoices.rejected, (state, payload) => {
				state.reservationsInvoices.loading = 'idle';
				state.reservationsInvoices.error = payload;
			})
			.addCase(
				getReservationsInvoices.fulfilled,
				(state, { payload }) => {
					state.reservationsInvoices.data = payload;
					state.reservationsInvoices.loading = 'idle';
				}
			);

			builder.addCase(ThunkValidateReservationDocument.pending, (state, action) => {
				state.validateReservationDocument.loading = 'pending';
			});
			builder.addCase(ThunkValidateReservationDocument.fulfilled, (state, { payload }) => {
				state.validateReservationDocument.loading = 'succeeded';
				state.validateReservationDocument.data = payload;
			});
			builder.addCase(ThunkValidateReservationDocument.rejected, (state, { payload }) => {
				state.validateReservationDocument.loading = 'failed';
				state.validateReservationDocument.error = payload as string;
			});
	}
});

export const { setReservationsInvoices } = partnerReservationSlice.actions;
