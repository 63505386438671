import {
	getProperty,
	Loading,
	useAppDispatch,
	useAppSelector
} from '@hospy/store';
import {
	getSubscription2025,
	getSubscriptionsToRenew
} from '@hospy/store/subscriptions/thunks';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

const useValidateShowNewRates = () => {
	const dispatch = useAppDispatch();
	const location = useLocation();
	const navigate = useNavigate();
	//
	const [loading, setLoading] = useState<Loading>('idle');
	//
	const { propertyId, user, property } = useAppSelector(({ user }) => user);
	const extras = user?.extras || [];
	const showNewRates =
		extras?.find((e) => e.slug === `showNewRates-2024.${propertyId}`)
			?.value === 'true' || false;

	const { subscription2025, subscriptionToRenew } = useAppSelector(
		({ subscriptions }) => subscriptions
	);

	const _property = subscription2025?.data?.properties?.find(
		(e) => e.id === propertyId
	);

	useEffect(() => {
		if (
			location.pathname.includes('/show-new-rates') &&
			(moment(property?.createdAt).year() > 2024 ||
				moment(user?.createdAt).year() > 2024)
		) {
			navigate('/');
		}
	}, [user?._id, property?._id, location.pathname]);

	useEffect(() => {
		if (
			subscription2025.loading === 'succeeded' &&
			propertyId &&
			!_property?.id
		) {
			navigate('/');
		}
	}, [propertyId, JSON.stringify(_property), subscription2025.loading]);

	useEffect(() => {
		if (showNewRates && location.pathname.includes('/show-new-rates')) {
			navigate('/');
		}
	}, [showNewRates, location.pathname]);

	useEffect(() => {
		if (showNewRates) return;
		if (user?._id && propertyId && subscription2025.loading === 'idle') {
			let _propertyId;
			if (
				user?.properties?.length &&
				user?.properties.find((e) => e._id === propertyId)?.rol ===
					'collaborator'
			) {
				_propertyId = propertyId;
			}
			dispatch(getSubscription2025({ propertyId: _propertyId }));
		}
	}, [subscription2025.loading, user?._id, propertyId, showNewRates]);

	useEffect(() => {
		if (showNewRates) return;
		if (user?._id && propertyId && subscriptionToRenew.loading === 'idle') {
			let _propertyId;
			if (
				user?.properties?.length &&
				user?.properties.find((e) => e._id === propertyId)?.rol ===
					'collaborator'
			) {
				_propertyId = propertyId;
			}
			dispatch(getSubscriptionsToRenew(_propertyId));
		}
	}, [subscriptionToRenew.loading, user?._id, propertyId, showNewRates]);

	useEffect(() => {
		if (propertyId && !property) {
			dispatch(getProperty(propertyId));
		}
	}, [propertyId, property]);

	useEffect(() => {
		if (moment(property?.createdAt).year() > 2024) return;
		if (moment(user?.createdAt).year() > 2024) return;
		if (user?.userViewAdmin) return;

		const last = moment().isSameOrBefore(
			moment('2025-01-31', 'YYYY-MM-DD'),
			'days'
		);

		if (!last && location.pathname.includes('/show-new-rates')) {
			navigate('/');
			return;
		}

		if (last && _property?.id && user?._id) {
			if (
				!showNewRates &&
				(!location.pathname.includes('/show-new-rates') ||
					!location.pathname.includes('/onboarding'))
			) {
				navigate('/show-new-rates');
				return;
			}

			if (showNewRates && location.pathname.includes('/show-new-rates')) {
				navigate('/');
			}
		}
	}, [
		location.pathname,
		showNewRates,
		JSON.stringify(_property),
		user?._id,
		property?._id
	]);

	return { loading };
};
export default useValidateShowNewRates;
