/* eslint-disable @typescript-eslint/no-explicit-any */
import {
	fetchGetReservations,
	fetchInvoiceReservations,
	RequestValidateDocument
} from '@hospy/util-api';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { Find } from './interface';

export const getReservations = createAsyncThunk(
	'partner-reservations/getReservations',
	async (find: Find, { rejectWithValue }) => {
		try {
			const response = await fetchGetReservations(find);
			return response;
		} catch (error: any) {
			console.error({ error });
			rejectWithValue('Error');
		}
	}
);

export const getReservationsInvoices = createAsyncThunk(
	'partner-reservations/getReservationsInvoices',
	async (
		{ filter, appId, partnerIntegrationId, page }: any,
		{ rejectWithValue, getState }
	) => {
		try {
			const state: any = getState();

			const { statusCode, data, meta } = await fetchInvoiceReservations({
				partnerIntegrationId,
				appId,
				propertyId: state.user.propertyId,
				filter,
				page: page || '1',
				limit: '1000'
			});
			if (statusCode !== 200)
				return rejectWithValue('No se pudo obtener las facturas');

			return { data, meta };
		} catch (error: any) {
			console.error(error);
			return rejectWithValue('Error');
		}
	}
);

export const ThunkValidateReservationDocument = createAsyncThunk(
	`spa/${RequestValidateDocument.name}`,
	async (params: {
		pathParams:{_id: string}
	}, { rejectWithValue }) => {
		const response = await RequestValidateDocument(params);
		if (response.status === 'success' && response) return response;
		return rejectWithValue(response);
	}
);
