/* eslint-disable @typescript-eslint/no-explicit-any */
import { createSlice, current } from '@reduxjs/toolkit';
import { initialState } from './constants';
import {
	createDiscounts,
	deleteDiscounts,
	deletePaymentMethod,
	getCustomer,
	getDiscounts,
	getIntentStripe,
	getInvoices,
	getLastInvoice,
	getPaymentMethods,
	getSubscription,
	getSubscription2025,
	getSubscriptionsToRenew,
	getSubscriptionsToRenewPlan,
	updateCustomer,
	updateCustomerStripe
} from './thunks';

export const subscriptionsSlice = createSlice({
	name: 'subscription',
	initialState,
	reducers: {
		setStateSubscription: (state: any, action) => {
			state[action.payload.state] = action.payload.value;
		},
		resetCustomerUpdateStripe: (state) => {
			state.updateCustomerStripe = initialState.updateCustomerStripe;
		}
	},
	extraReducers: (builder) => {
		builder
			.addCase(getSubscription.pending, (state) => {
				state.subscription.loading = 'pending';
			})
			.addCase(getSubscription.rejected, (state, payload) => {
				state.subscription.loading = 'idle';
				state.subscription.error = payload;
			})
			.addCase(getSubscription.fulfilled, (state, { payload }) => {
				state.subscription.data = payload;
				state.subscription.loading = 'idle';
			});

		builder
			.addCase(getSubscription2025.pending, (state) => {
				state.subscription.loading = 'pending';
			})
			.addCase(getSubscription2025.rejected, (state, payload) => {
				state.subscription2025.loading = 'failed';
				state.subscription2025.error = payload;
			})
			.addCase(getSubscription2025.fulfilled, (state, { payload }) => {
				state.subscription2025.data = payload;
				state.subscription2025.loading = 'succeeded';
			});

		builder
			.addCase(getCustomer.pending, (state) => {
				state.customer.loading = 'pending';
			})
			.addCase(getCustomer.rejected, (state, payload) => {
				state.customer.loading = 'idle';
				state.customer.error = payload;
			})
			.addCase(getCustomer.fulfilled, (state, { payload }) => {
				state.customer.data = payload;
				state.customer.loading = 'idle';
			})

			.addCase(updateCustomer.pending, (state) => {
				state.updateCustomer.loading = 'pending';
			})
			.addCase(updateCustomer.rejected, (state, payload) => {
				state.updateCustomer.loading = 'idle';
				state.updateCustomer.error = payload;
			})
			.addCase(updateCustomer.fulfilled, (state, { payload }) => {
				state.updateCustomer.loading = 'succeeded';
			})

			.addCase(getIntentStripe.pending, (state) => {
				state.intentStripe.loading = 'pending';
			})
			.addCase(getIntentStripe.rejected, (state, payload) => {
				state.intentStripe.loading = 'idle';
				state.intentStripe.error = payload;
			})
			.addCase(getIntentStripe.fulfilled, (state, { payload }) => {
				state.intentStripe.loading = 'succeeded';
				state.intentStripe.data = payload;
			})

			.addCase(getPaymentMethods.pending, (state) => {
				state.paymentMethods.loading = 'pending';
			})
			.addCase(getPaymentMethods.rejected, (state, payload) => {
				state.paymentMethods.loading = 'idle';
				state.paymentMethods.error = payload;
			})
			.addCase(getPaymentMethods.fulfilled, (state, { payload }) => {
				state.paymentMethods.loading = 'succeeded';
				state.paymentMethods.data = payload;
			})

			.addCase(deletePaymentMethod.pending, (state) => {
				state.deletePaymentMethod.loading = 'pending';
			})
			.addCase(deletePaymentMethod.rejected, (state, payload) => {
				state.deletePaymentMethod.loading = 'idle';
				state.deletePaymentMethod.error = payload;
			})
			.addCase(deletePaymentMethod.fulfilled, (state, { payload }) => {
				state.deletePaymentMethod.loading = 'succeeded';
				const paymentMethods = current(state.paymentMethods);
				state.paymentMethods.data = paymentMethods.data.filter(
					(item: any) => item.id !== payload.id
				);
			})

			.addCase(updateCustomerStripe.pending, (state) => {
				state.updateCustomerStripe.loading = 'pending';
			})
			.addCase(updateCustomerStripe.rejected, (state, payload) => {
				state.updateCustomerStripe.loading = 'idle';
				state.updateCustomerStripe.error = payload;
			})
			.addCase(updateCustomerStripe.fulfilled, (state, { payload }) => {
				if (payload.invoice_settings?.default_payment_method) {
					const paymentMethods = current(state.paymentMethods);
					paymentMethods.data.forEach((item: any, index: number) => {
						state.paymentMethods.data[index].default =
							item.id ===
							payload.invoice_settings?.default_payment_method;
					});
				}
				state.updateCustomerStripe.loading = 'succeeded';
			})

			.addCase(getInvoices.pending, (state) => {
				state.invoices.loading = 'pending';
			})
			.addCase(getInvoices.rejected, (state, { payload }) => {
				state.invoices.loading = 'idle';
				state.invoices.error = payload;
			})
			.addCase(getInvoices.fulfilled, (state, { payload }) => {
				state.invoices.loading = 'succeeded';
				state.invoices.data = payload;
			})

			//
			.addCase(getLastInvoice.pending, (state) => {
				state.lastInvoice.loading = 'pending';
			})
			.addCase(getLastInvoice.rejected, (state, { payload }) => {
				state.lastInvoice.loading = 'idle';
				state.lastInvoice.error = payload;
			})
			.addCase(getLastInvoice.fulfilled, (state, { payload }) => {
				if (payload.length > 0) {
					state.lastInvoice.loading = 'succeeded';
					state.lastInvoice.data = payload[0];
				} else {
					state.lastInvoice.loading = 'failed';
				}
			})

			.addCase(getDiscounts.pending, (state) => {
				state.discounts.loading = 'pending';
				if (state.discounts.data?.docs) state.discounts.data.docs = [];
			})
			.addCase(getDiscounts.rejected, (state, { payload }) => {
				state.discounts.loading = 'idle';
				state.discounts.error = payload;
			})
			.addCase(getDiscounts.fulfilled, (state, { payload }) => {
				state.discounts.loading = 'succeeded';
				state.discounts.data = payload;
			})

			.addCase(createDiscounts.pending, (state) => {
				state.createDiscount.loading = 'pending';
			})
			.addCase(createDiscounts.rejected, (state, { payload }) => {
				state.createDiscount.loading = 'idle';
				state.createDiscount.error = payload;
			})
			.addCase(createDiscounts.fulfilled, (state, { payload }) => {
				state.createDiscount.loading = 'succeeded';
				//const discounts: any = current(state.discounts) || [];
				//state.discounts.data = [...discounts.data, payload]
			})

			.addCase(deleteDiscounts.pending, (state) => {
				state.deleteDiscount.loading = 'pending';
			})
			.addCase(deleteDiscounts.rejected, (state, { payload }) => {
				state.deleteDiscount.loading = 'idle';
				state.deleteDiscount.error = payload;
			})
			.addCase(deleteDiscounts.fulfilled, (state, { payload }) => {
				state.deleteDiscount.loading = 'succeeded';
				const discounts = current(state.discounts);
				state.discounts.data.data = discounts.data.data.filter(
					(item: any) => item._id !== payload.deleteId
				);
				state.discounts.data.meta.count = discounts.data.meta.count - 1;
			})

			.addCase(getSubscriptionsToRenew.pending, (state) => {
				state.subscriptionToRenew.loading = 'pending';
			})
			.addCase(getSubscriptionsToRenew.rejected, (state, { payload }) => {
				state.subscriptionToRenew.loading = 'idle';
				state.subscriptionToRenew.error = payload;
			})
			.addCase(
				getSubscriptionsToRenew.fulfilled,
				(state, { payload }) => {
					state.subscriptionToRenew.loading = 'succeeded';
					state.subscriptionToRenew.data = payload.data;
				}
			)

			.addCase(getSubscriptionsToRenewPlan.pending, (state) => {
				state.subscriptionToRenewPlan.loading = 'pending';
			})
			.addCase(getSubscriptionsToRenewPlan.rejected, (state, { payload }) => {
				state.subscriptionToRenewPlan.loading = 'idle';
				state.subscriptionToRenewPlan.error = payload;
			})
			.addCase(
				getSubscriptionsToRenewPlan.fulfilled,
				(state, { payload }) => {
					state.subscriptionToRenewPlan.loading = 'succeeded';
					state.subscriptionToRenewPlan.data = payload.data;
				}
			);
	}
});

export const { setStateSubscription, resetCustomerUpdateStripe } =
	subscriptionsSlice.actions;
